<template ref="tableCollaboratorsEgov">
  <div>
    <v-card class="table-cedidos-container">
      <v-card-title>
        <TableSearch
          :label="txt.tableSearchLabel"
          v-model="filter.filterTerm"
          @input="(items) => filterFromTable(items)"
          :disabled="loadingTable"
        ></TableSearch>

        <v-spacer></v-spacer>

        <ExportButton :is-disabled="isDisabled" @exportar="exportar" />
      </v-card-title>
      <div id="colaboradores-container">
        <div :id="idTable + '_scrollDuplicate'" class="table_scroll_duplicate">
          <div :id="idTable + '_dummy'"></div>
        </div>

        <v-data-table
          :id="idTable"
          :loading="loadingTable"
          :search="search"
          :headers="headers"
          :options.sync="pagination"
          :items="collaborators"
          :page="pagination.page"
          :server-items-length="totalCollaborators"
          :footer-props="{
            disablePagination: collaborators.length === 0 || loadingTable,
            itemsPerPageOptions:
              totalCollaborators < 50
                ? [
                    { value: 50, text: '50' },
                    { value: 100, text: '100', disabled: true },
                    { value: 250, text: '250', disabled: true },
                  ]
                : totalCollaborators < 100
                ? [
                    { value: 50, text: '50' },
                    { value: 100, text: '100' },
                    { value: 250, text: '250', disabled: true },
                  ]
                : [
                    { value: 50, text: '50' },
                    { value: 100, text: '100' },
                    { value: 250, text: '250' },
                  ],
            disableItemsPerPage: collaborators.length === 0 || loadingTable,
            itemsPerPageText: `Cessões por página: `,
            expandIcon: 'mdi-dots-horizontal',
          }"
          :no-data-text="`Não há registros de cessões a serem exibidos.`"
          @current-items="onTableInput"
        >
          <template v-slot:footer.page-text="items">
            {{ loadingTable ? 0 : items.pageStart }} - {{ loadingTable ? 0 : items.pageStop }} de
            {{ loadingTable ? 0 : items.itemsLength }} Cessões
          </template>
          <template slot="body" v-if="loadingTable">
            <tr>
              <td v-for="loadItem in headers">
                <v-skeleton-loader :max-width="loadItem.width" class="loaderCss"></v-skeleton-loader>
              </td>
            </tr>
          </template>

          <template v-slot:item.empresaOrigem="{ item }">
            <span class="cedidos-origin">
              <AGTooltip :tooltipText="item.empresaOrigem" v-if="checkLength(item.empresaOrigem)">
                <template slot:tooltipArea>
                  <div class="text-no-wrap text-truncate">
                    {{ item.empresaOrigem }}
                  </div>
                </template>
              </AGTooltip>
              <div class="text-no-wrap text-truncate" v-else>
                {{ item.empresaOrigem }}
              </div>
              <div class="cedidos-cnpj-origin">
                {{ item.cnpjOrigem | cnpj }}
              </div>
            </span>
          </template>
          <template v-slot:item.tpRegPrev="{ item }">
            <AGTooltip :tooltipText="item.tpRegPrev" v-if="checkLength(item.tpRegPrev)">
              <template slot:tooltipArea>
                <div class="cedidos-regime-previdencia">
                  <div class="text-no-wrap text-truncate">
                    {{ item.tpRegPrev }}
                  </div>
                </div>
              </template>
            </AGTooltip>
            <div class="text-no-wrap text-truncate" v-else>
              {{ item.tpRegPrev }}
            </div>
          </template>

          <template v-slot:item.empresaDestino="{ item }">
            <span class="cedidos-destiny">
              <AGTooltip :tooltipText="item.empresaDestino" v-if="checkLength(item.empresaDestino)">
                <template slot:tooltipArea>
                  <div class="text-no-wrap text-truncate">
                    {{ item.empresaDestino }}
                  </div>
                </template>
              </AGTooltip>
              <div class="text-no-wrap text-truncate" v-else>
                {{ item.empresaDestino }}
              </div>
              <div class="cedidos-cnpj-destiny">
                {{ item.cnpjDestino | cnpj }}
              </div>
            </span>
          </template>
          <template v-slot:item.situacao="{ item }">
            <div class="text-no-wrap">
              {{ item.situacao | situation }}
            </div>
          </template>
          <template v-slot:item.remuneracao="{ item }">
            <div class="text-no-wrap">
              {{ item.remuneracao | respRemun }}
            </div>
          </template>
          <template v-slot:item.cpf="{ item }">
            <div class="text-no-wrap">
              {{ item.cpf | cpf }}
            </div>
          </template>

          <template v-slot:no-data>
            <div id="noDataText" class="d-flex justify-center" :style="getTableWidth">
              <p>Não há registros de {{ funcTitle }} a serem exibidos.</p>
            </div>
          </template>
        </v-data-table>
      </div>
    </v-card>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';

import { eventBus } from '@/main.js';
import TableMixin from "@/mixins/Table.js"

export default {
  name: 'TableColaboradores',

  components: {
    FilterButton: () => import('./FilterForTable.vue'),
    ExportButton: () => import('./ExportButton.vue'),
    AGTooltip: () => import('@/router/views/commons/AGTooltip.vue'),
    TableSearch: () => import('@/router/views/commons/TableSearch.vue'),
  },

  mixins: [TableMixin],

  updated() {},

  watch: {
    collaborators(value) {
      if (value.length === 0) {
        this.search = '';
      }
    },
  },
  data() {
    return {
      idTable: 'tablec',
      showGraph: false,
      funcTitle: process.env.VUE_APP_TAGFUNC,
      up: false,
      txt: { tableSearchLabel: 'Pesquisar por CNPJ, matrícula ou CPF' },
      toast: {
        message: '',
        type: '',
        show: false,
        topToast: 0,
      },

      search: '',

      headers: [
        { text: '', value: 'id_column', width: '100%', sortable: false },
        { text: 'Origem', value: 'empresaOrigem', width: '100%', sortable: true },
        { text: 'Destino', value: 'empresaDestino', width: '100%', sortable: true },
        { text: 'CPF', value: 'cpf', width: '150px', sortable: true },
        { text: 'Matrícula', value: 'matricula', width: '120px', sortable: true },
        { text: 'Regime Previdenciário', value: 'tpRegPrev', width: '100px', sortable: true },
        { text: 'Situação de cessão', value: 'situacao', width: '140px', sortable: true },
        { text: 'Inicio', value: 'dataInicioCessao', width: '78px', sortable: true },
        { text: 'Fim', value: 'dataTerminoCessao', width: '78px', sortable: true },
        { text: 'Remuneração na origem', value: 'remuneracao', width: '78px', sortable: true },
      ],
      customStyleSkeleton:
        'text-align: start;color: #e7e7fa;font-family: "Roboto";font-weight: 700;font-size: 28px;letter-spacing: 1%;height: 51px;flex-direction: row;align-items: flex-start; border-left: 2px solid #ffffff',
    };
  },

  computed: {
    ...mapGetters({
      companiesList: 'egov/getCompaniesList',
      collaborators: 'esocial/esocialCedidos/getColaboradoresList',
      loadingTable: 'esocial/esocialCedidos/getLoadingColaboradoresList',
      paginationFilter: 'esocial/esocialCedidos/getPagination',
      totalCollaborators: 'esocial/esocialCedidos/getTotCollaborators',
      filter: 'esocial/esocialCedidos/getFiltersSaved',
      userInfo: 'user/userInfo',
      scheduleReport: 'esocial/esocialCedidos/getScheduleReportStatus',
    }),
    pagination: {
      get: function () {
        return this.paginationFilter;
      },
      set: function (value) {
        this.setPagination(value);
      },
    },
    isDisabled() {
      if (this.collaborators.length < 1) {
        return true;
      } else if (this.loadingTable) {
        return true;
      } else {
        return false;
      }
    },
  },

  methods: {
    ...mapActions('esocial/esocialCedidos', ['init', 'setPagination', 'exportCollaborators', 'filterFromTable']),

    async exportar() {
      await this.exportCollaborators();
      if (this.scheduleReport) {
        this.showToast(
          'Recebemos seu pedido com sucesso! Nos menus Agnes em: Documentos e Download. Assim que concluído, o arquivo estará disponível.',
          'success',
          78,
        );
      } else {
        this.showToast('Erro ao exportar relatório.', 'error', 78);
      }
    },
    checkLength(item) {
      if (item) {
        if (item.length > 45) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    showToast(message, type, topToast) {
      this.toast.show = true;
      this.toast.type = type;
      this.toast.message = message;
      this.toast.topToast = topToast;
      eventBus.$emit('openToast', this.toast);
    },
  }
};
</script>
<style lang="scss" scoped>
@import './src/design/components/table.scss';
@import './src/design/components/tooltip.scss';

.table-cedidos-container {
  padding: 0;
  border: $border-width-hairline $border-default $neutral-color-low-dark;
}

.cedidos-origin {
  div {
    max-width: 380px;
    min-width: 320px;
  }
  .cedidos-cnpj-origin {
    font-size: $font-size-xxs;
    line-height: $line-height-md;
  }
}
.cedidos-regime-previdencia {
  width: 330px;
}
.cedidos-destiny {
  div {
    max-width: 380px;
    min-width: 320px;
  }
  .cedidos-cnpj-destiny {
    font-size: $font-size-xxs;
    line-height: $line-height-md;
  }
}
</style>
