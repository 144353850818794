var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-card",
        { staticClass: "table-cedidos-container" },
        [
          _c(
            "v-card-title",
            [
              _c("TableSearch", {
                attrs: {
                  label: _vm.txt.tableSearchLabel,
                  disabled: _vm.loadingTable,
                },
                on: { input: (items) => _vm.filterFromTable(items) },
                model: {
                  value: _vm.filter.filterTerm,
                  callback: function ($$v) {
                    _vm.$set(_vm.filter, "filterTerm", $$v)
                  },
                  expression: "filter.filterTerm",
                },
              }),
              _c("v-spacer"),
              _c("ExportButton", {
                attrs: { "is-disabled": _vm.isDisabled },
                on: { exportar: _vm.exportar },
              }),
            ],
            1
          ),
          _c(
            "div",
            { attrs: { id: "colaboradores-container" } },
            [
              _c(
                "div",
                {
                  staticClass: "table_scroll_duplicate",
                  attrs: { id: _vm.idTable + "_scrollDuplicate" },
                },
                [_c("div", { attrs: { id: _vm.idTable + "_dummy" } })]
              ),
              _c(
                "v-data-table",
                {
                  attrs: {
                    id: _vm.idTable,
                    loading: _vm.loadingTable,
                    search: _vm.search,
                    headers: _vm.headers,
                    options: _vm.pagination,
                    items: _vm.collaborators,
                    page: _vm.pagination.page,
                    "server-items-length": _vm.totalCollaborators,
                    "footer-props": {
                      disablePagination:
                        _vm.collaborators.length === 0 || _vm.loadingTable,
                      itemsPerPageOptions:
                        _vm.totalCollaborators < 50
                          ? [
                              { value: 50, text: "50" },
                              { value: 100, text: "100", disabled: true },
                              { value: 250, text: "250", disabled: true },
                            ]
                          : _vm.totalCollaborators < 100
                          ? [
                              { value: 50, text: "50" },
                              { value: 100, text: "100" },
                              { value: 250, text: "250", disabled: true },
                            ]
                          : [
                              { value: 50, text: "50" },
                              { value: 100, text: "100" },
                              { value: 250, text: "250" },
                            ],
                      disableItemsPerPage:
                        _vm.collaborators.length === 0 || _vm.loadingTable,
                      itemsPerPageText: `Cessões por página: `,
                      expandIcon: "mdi-dots-horizontal",
                    },
                    "no-data-text": `Não há registros de cessões a serem exibidos.`,
                  },
                  on: {
                    "update:options": function ($event) {
                      _vm.pagination = $event
                    },
                    "current-items": _vm.onTableInput,
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "footer.page-text",
                      fn: function (items) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(_vm.loadingTable ? 0 : items.pageStart) +
                              " - " +
                              _vm._s(_vm.loadingTable ? 0 : items.pageStop) +
                              " de " +
                              _vm._s(_vm.loadingTable ? 0 : items.itemsLength) +
                              " Cessões "
                          ),
                        ]
                      },
                    },
                    {
                      key: "item.empresaOrigem",
                      fn: function ({ item }) {
                        return [
                          _c(
                            "span",
                            { staticClass: "cedidos-origin" },
                            [
                              _vm.checkLength(item.empresaOrigem)
                                ? _c(
                                    "AGTooltip",
                                    {
                                      attrs: {
                                        tooltipText: item.empresaOrigem,
                                      },
                                    },
                                    [
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "text-no-wrap text-truncate",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(item.empresaOrigem) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ],
                                    ],
                                    2
                                  )
                                : _c(
                                    "div",
                                    {
                                      staticClass: "text-no-wrap text-truncate",
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(item.empresaOrigem) + " "
                                      ),
                                    ]
                                  ),
                              _c(
                                "div",
                                { staticClass: "cedidos-cnpj-origin" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm._f("cnpj")(item.cnpjOrigem)) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                    {
                      key: "item.tpRegPrev",
                      fn: function ({ item }) {
                        return [
                          _vm.checkLength(item.tpRegPrev)
                            ? _c(
                                "AGTooltip",
                                { attrs: { tooltipText: item.tpRegPrev } },
                                [
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "cedidos-regime-previdencia",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "text-no-wrap text-truncate",
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(item.tpRegPrev) + " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                ],
                                2
                              )
                            : _c(
                                "div",
                                { staticClass: "text-no-wrap text-truncate" },
                                [_vm._v(" " + _vm._s(item.tpRegPrev) + " ")]
                              ),
                        ]
                      },
                    },
                    {
                      key: "item.empresaDestino",
                      fn: function ({ item }) {
                        return [
                          _c(
                            "span",
                            { staticClass: "cedidos-destiny" },
                            [
                              _vm.checkLength(item.empresaDestino)
                                ? _c(
                                    "AGTooltip",
                                    {
                                      attrs: {
                                        tooltipText: item.empresaDestino,
                                      },
                                    },
                                    [
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "text-no-wrap text-truncate",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(item.empresaDestino) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ],
                                    ],
                                    2
                                  )
                                : _c(
                                    "div",
                                    {
                                      staticClass: "text-no-wrap text-truncate",
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(item.empresaDestino) + " "
                                      ),
                                    ]
                                  ),
                              _c(
                                "div",
                                { staticClass: "cedidos-cnpj-destiny" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm._f("cnpj")(item.cnpjDestino)) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                    {
                      key: "item.situacao",
                      fn: function ({ item }) {
                        return [
                          _c("div", { staticClass: "text-no-wrap" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm._f("situation")(item.situacao)) +
                                " "
                            ),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "item.remuneracao",
                      fn: function ({ item }) {
                        return [
                          _c("div", { staticClass: "text-no-wrap" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm._f("respRemun")(item.remuneracao)) +
                                " "
                            ),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "item.cpf",
                      fn: function ({ item }) {
                        return [
                          _c("div", { staticClass: "text-no-wrap" }, [
                            _vm._v(" " + _vm._s(_vm._f("cpf")(item.cpf)) + " "),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "no-data",
                      fn: function () {
                        return [
                          _c(
                            "div",
                            {
                              staticClass: "d-flex justify-center",
                              style: _vm.getTableWidth,
                              attrs: { id: "noDataText" },
                            },
                            [
                              _c("p", [
                                _vm._v(
                                  "Não há registros de " +
                                    _vm._s(_vm.funcTitle) +
                                    " a serem exibidos."
                                ),
                              ]),
                            ]
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _vm.loadingTable
                    ? _c("template", { slot: "body" }, [
                        _c(
                          "tr",
                          _vm._l(_vm.headers, function (loadItem) {
                            return _c(
                              "td",
                              [
                                _c("v-skeleton-loader", {
                                  staticClass: "loaderCss",
                                  attrs: { "max-width": loadItem.width },
                                }),
                              ],
                              1
                            )
                          }),
                          0
                        ),
                      ])
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }